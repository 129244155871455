<template>
    <div v-if="event.data" class="event-card">
        <EventThumbnail :event="event" class="thumbnail" />

        <div class="top-info">
            <CategoryPill :category="event.data.category" />
        </div>
        <PrismicLink v-if="isFilled.link(titleLink)" :field="titleLink" class="event-text">
            <h3 class="title">{{ event.data.title }}</h3>
        </PrismicLink>
        <div v-else class="event-text">
            <h3 class="title">{{ event.data.title }}</h3>
        </div>
        <div class="tags">
            <TagPill v-for="tag in event.tags" :key="tag" :tag="tag" />
        </div>
        <PrismicLink
            v-if="(isBefore(new Date(), new Date(event.data?.date as string)) || isSameDay(new Date(), new Date(event.data?.date as string))) && isFilled.link(event.data?.signupLink)"
            :field="event.data.signupLink"
            class="link"
        >
            {{ t('resources.events.signup') }}
        </PrismicLink>
        <PrismicLink v-else-if="isFilled.link(event.data?.replayLink)" :field="event.data?.replayLink ?? {link_type: 'Any'}" class="link">
            {{ t('resources.events.watchReplay') }}
        </PrismicLink>
    </div>
</template>

<script lang="ts" setup>
    import type {EventDocument, EventDocumentData} from '@prismicio-types';
    import {useTranslation} from '#imports';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import {PrismicLink} from '@prismicio/vue';
    import {useDateHelpers} from '~/composables/useDateHelpers';
    import EventThumbnail from '~/components/EventThumbnail.vue';
    import CategoryPill from '~/components/CategoryPill.vue';
    import TagPill from '~/components/TagPill.vue';
    import {isFilled} from '@prismicio/helpers';
    const {isBefore, isSameDay} = useDateHelpers();
    const props = defineProps<{
        event: EventDocument | FilledContentRelationshipField<'event', string, EventDocumentData>;
    }>();
    const {t} = useTranslation();
    const titleLink =
        isBefore(new Date(), new Date(props.event.data?.date as string)) || isSameDay(new Date(), new Date(props.event.data?.date as string))
            ? props.event.data?.signupLink
            : props.event.data?.replayLink;
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .event-card {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        .thumbnail {
            width: 100%;
            height: 240px;
            object-fit: cover;
            &.success-thumbnail {
                object-fit: contain;
                height: auto;
            }
            @media (max-width: $mobile-breakpoint) {
                max-height: 70vw;
            }
        }

        .top-info {
            margin-top: var(--joy-core-spacing-4);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .event-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .title {
            margin-top: var(--joy-core-spacing-5);
            margin-bottom: var(--joy-core-spacing-9);
        }

        .tags {
            margin-top: var(--joy-core-spacing-4);
            display: flex;
            flex-wrap: wrap;
            gap: var(--joy-core-spacing-1);
        }
        .link {
            @include link;
            margin-top: var(--joy-core-spacing-5);
        }
    }
</style>
