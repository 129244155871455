function isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

function isBefore(date1: Date, date2: Date): boolean {
    return date1.getTime() < date2.getTime();
}

function getDaysDifference(date1: Date, date2: Date): number {
    return Math.floor(Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24)) + 1;
}
export function useDateHelpers() {
    return {
        isSameDay,
        isBefore,
        getDaysDifference,
    };
}
