<template>
    <div class="event-thumbnail-wrapper">
        <PrismicImage v-if="event.data?.thumbnail" :field="event.data?.thumbnail" class="thumbnail" />
        <div v-if="event.data?.date" class="date-info">
            <template v-if="isBefore(new Date(), new Date(event.data?.date)) || isSameDay(new Date(), new Date(event.data?.date))">
                <div class="date">
                    <Calendar class="icon" />
                    <span>{{ new Date(event.data?.date).toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric'}) }}</span>
                </div>
                <span class="delay">
                    <template v-if="isSameDay(new Date(event.data?.date), new Date())">
                        {{ t('resources.events.today') }}
                    </template>
                    <template v-else-if="getDaysDifference(new Date(event.data?.date), new Date()) === 1">
                        {{ t('resources.events.tomorrow') }}
                    </template>
                    <template v-else>
                        {{ t('resources.events.inDays', [getDaysDifference(new Date(event.data?.date), new Date()).toString()]) }}
                    </template>
                </span>
            </template>
            <PrismicLink v-else-if="isFilled.link(event.data?.replayLink)" :field="event.data?.replayLink" class="date">
                <Player class="icon" />
                <span>
                    {{
                        t('resources.events.replayDate', [
                            new Date(event.data?.date).toLocaleDateString(locale, {
                                month: 'long',
                                day: 'numeric',
                            }),
                        ])
                    }}
                </span>
            </PrismicLink>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {inject} from 'vue';
    import {useTranslation} from '#imports';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import type {EventDocument, EventDocumentData} from '@prismicio-types';
    import {PrismicImage, PrismicLink} from '@prismicio/vue';
    import Calendar from '~/components/icons/Calendar.vue';
    import Player from '~/components/icons/Player.vue';
    import {USER_LOCALE} from '~/app/keys';
    import {useDateHelpers} from '~/composables/useDateHelpers';
    import {isFilled} from '@prismicio/helpers';

    defineProps<{event: EventDocument | FilledContentRelationshipField<'event', string, EventDocumentData>}>();
    const locale = inject(USER_LOCALE);

    const {isBefore, isSameDay, getDaysDifference} = useDateHelpers();
    const {t} = useTranslation();
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .event-thumbnail-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        .thumbnail {
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, transparent 33.27%, black 117%);
            background-blend-mode: darken;
            z-index: 2;
        }
        .date-info {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 3;
            display: flex;
            flex-direction: column;
            padding: var(--joy-core-spacing-9);
            * {
                font-family: var(--joy-font-family-title);
            }
            .date {
                color: white;
                font-size: 40px;
                font-weight: 800;
                line-height: 100%;
                @media (max-width: $mobile-breakpoint) {
                    font-size: var(--joy-font-size-primary-800);
                }
            }
            .delay {
                color: var(--joy-color-quaternary-50);
                margin-left: calc(30px + var(--joy-core-spacing-4));
                font-size: var(--joy-font-size-primary-800);
                @media (max-width: $mobile-breakpoint) {
                    font-size: var(--joy-font-size-primary-400);
                    margin-left: calc(19px + var(--joy-core-spacing-4));
                }
            }
            .icon {
                width: 30px;
                height: 30px;
                margin-right: var(--joy-core-spacing-4);
                * {
                    fill: white;
                }
                @media (max-width: $mobile-breakpoint) {
                    height: 19px;
                    width: 19px;
                }
            }
        }
    }
</style>
